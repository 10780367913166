import { headerSchema } from '@ecomm/shared-components'
import { type TypeOf, z } from '@simplisafe/ewok'

import { citiesGridSchema } from './citiesGrid'
import { crimeBannerSchema } from './crimeBanner'
import { layoutReferenceSchema } from './layoutReference'
import { linkSchema } from './link'
import { packageSchema, syntheticPackageSchema } from './package'
import { smallTextSchema } from './smallText'

const layoutBodySchema = z.union([
  smallTextSchema,
  layoutReferenceSchema,
  citiesGridSchema,
  linkSchema,
  packageSchema,
  crimeBannerSchema,
  syntheticPackageSchema
])

export const layoutSchema = z.object({
  __typename: z.literal('ContentfulLayout'),
  contentful_id: z.string(),
  id: z.string(),
  internalTitle: z.string(),
  promoBannerStyle: z.string(),
  ready: z.boolean().default(true),
  header: headerSchema.optional(),
  components: z.array(layoutBodySchema),
  footer: z.object({
    contentful_id: z.string()
  })
})

const crimeInTheUKSchema = z.object({
  contentful_id: z.string(),
  layout: layoutSchema,
  slug: z.string(),
  type: z.enum(['Standard', 'Refurbished']).optional(),
  title: z.string(),
  breadcrumbTitle: z.string().optional()
})

export type CrimeInTheUKSchema = TypeOf<typeof crimeInTheUKSchema>

export default crimeInTheUKSchema
