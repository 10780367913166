import type { IconDropDownProps, OffersProps } from '@ecomm/ss-react-components'
import * as A from 'fp-ts/lib/Array'
import * as O from 'fp-ts/lib/Option'
import { pipe } from 'fp-ts/lib/function'
import * as S from 'fp-ts/lib/string'

import { ApolloRichText, ContentfulImage } from '@ecomm/contentful/components'
import type { Countries, Country, LeadGenForm } from '@ecomm/footer-data'

export const toOffersData = (
  leadGenerationForm: LeadGenForm,
  handleEmailSubmit: (email: string) => void,
  isSuccess: boolean,
  defaultEmail: string
): OffersProps => {
  const inputLabel = leadGenerationForm.title
  const placeHolder = leadGenerationForm.formCollection.items[0]?.placeholder
  const buttonText = leadGenerationForm.button.text
  const ctaContent = leadGenerationForm.note?.json ? (
    <ApolloRichText json={leadGenerationForm.note?.json} />
  ) : null
  // TODO: Retrieve from CTFL instead of hard coding it
  const errorMsg = 'Please enter a valid email address.'
  const success = {
    message: 'Thanks! Keep an eye on your email for tips, offers and more.',
    title: ''
  }
  const offersDataSpec = {
    buttonText,
    ctaContent,
    defaultEmail,
    errorMsg,
    inputLabel,
    onEmailSubmit: handleEmailSubmit,
    placeHolder,
    title: inputLabel
  }

  return isSuccess
    ? {
        ...offersDataSpec,
        success
      }
    : offersDataSpec
}

const toListItem = ({ linkUrl, linkSubtext, linkText, linkIcon }: Country) => ({
  href: linkUrl,
  srcIcon: <ContentfulImage classNameOverride="h-3 w-5" {...linkIcon} />,
  subtitle: linkSubtext,
  title: linkText
})

export const getCountryInformation = (
  countries: Countries,
  locale: string
): IconDropDownProps => {
  const isUS = S.Eq.equals(locale, 'en-US')
  const label = isUS ? 'United States' : 'United Kingdom'
  const listItems = pipe(countries, A.map(toListItem))

  const currentLocationIdx = pipe(
    listItems,
    A.findIndex(({ title }) => S.Eq.equals(title, label)),
    O.getOrElse(() => 0)
  )

  const positionDropDown: 'down' | 'up' = 'up'
  const eventShowDropDown: 'click' | 'hover' = 'click'
  return {
    a11yLabel: 'Current country is: ',
    eventShowDropdown: eventShowDropDown,
    label: listItems[currentLocationIdx]?.subtitle,
    listItem: listItems,
    positionDropdown: positionDropDown,
    srcIcon: listItems[currentLocationIdx]?.srcIcon
  }
}
