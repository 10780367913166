import { Spinner } from '@ecomm/cart-components'
import {
  FormField,
  FormSelect,
  FormTextInput
} from '@ecomm/checkout/components'
import { TermsOfSale } from '@ecomm/checkout/payment-components'
import { usStates } from '@ecomm/data-constants'
import { useLocale } from '@ecomm/data-hooks'
import {
  type CreatePartnerOrderResponse,
  createPartnerOrder
} from '@ecomm/partners-utils'
import { ContentfulRichText } from '@ecomm/shared-components'
import { setCookie } from '@ecomm/shared-cookies'
import { COOKIE_PREACTIVATION } from '@ecomm/shared-cookies'
import { visitorIdAtAt } from '@ecomm/tracking'
import classNames from 'classnames'
import { Form, Formik } from 'formik'
import * as E from 'fp-ts/lib/Either'
import { pipe } from 'fp-ts/lib/function'
import { useRef } from 'react'
import { match } from 'ts-pattern'
import { StringParam, useQueryParam } from 'use-query-params'

import {
  type FormQuerySchema,
  validationSchemaUK,
  validationSchemaUS
} from './schema'
import {
  COOKIE_MANDO_EXPIRES_TOKEN,
  cookiesOptions,
  getWebAppUrl
} from './utils'

export type FormValues = {
  readonly address1: string
  readonly address2: string
  readonly city: string
  readonly customerKey: string
  readonly email: string
  readonly firstName: string
  readonly lastName: string
  readonly optIn: boolean
  readonly partnerName: string
  readonly policyId: string
  readonly phone: string
  readonly state?: string
  readonly country?: string
  readonly postalCode: string
  readonly vidToken: string
}

export type EmbeddedPartnerForm = {
  readonly fields: FormQuerySchema['fields']
  readonly button: FormQuerySchema['button']
  readonly partnerName: string
  readonly note?: string
}
export function EmbeddedPartnerForm({
  fields,
  button,
  partnerName = '',
  note = ''
}: EmbeddedPartnerForm) {
  const locale = useLocale()
  const [keyParam] = useQueryParam('utm_key', StringParam)
  const [policyIdParam] = useQueryParam('utm_ID', StringParam)
  const setLoadingTimer = useRef<number>(0)

  const forwardPreactivationFlowToWebApp = (
    response: CreatePartnerOrderResponse
  ) => {
    const webAppUrl = getWebAppUrl()
    setCookie(COOKIE_PREACTIVATION, response.token, cookiesOptions)
    setCookie(COOKIE_MANDO_EXPIRES_TOKEN, response.expires, cookiesOptions)
    window.setTimeout(() => {
      window.location.href =
        webAppUrl +
        `/#/collect-monitoring?funnel&orderid=${response.orderId}&sid=${response.sid}}`
    }, 1500)
  }

  return (
    <div
      className="bg-neutral-light-100 grid px-4 py-8 md:py-8 lg:px-7"
      data-component="EmbeddedPartnerForm"
    >
      <Formik
        initialStatus={true}
        initialValues={{
          address1: '',
          address2: '',
          city: '',
          customerKey: keyParam || '',
          email: '',
          firstName: '',
          lastName: '',
          optIn: true,
          partnerName: partnerName || '',
          phone: '',
          policyId: policyIdParam || '',
          postalCode: '',
          vidToken: visitorIdAtAt() || 'NO_VISITOR_ID',
          state: ''
        }}
        onSubmit={async (
          formData: FormValues,
          { setSubmitting, setStatus, setFieldError }
        ) => {
          setSubmitting(true)
          return createPartnerOrder({
            ...formData,
            zoneCode: formData.state,
            postal: formData.postalCode
          })().then(response => {
            clearTimeout(setLoadingTimer.current)
            return pipe(
              response,
              E.match(
                (e: Error) => {
                  const alreadyRedeemed = e.message.includes('Already Redeemed')
                  const message = alreadyRedeemed
                    ? `It looks like this offer may have already been redeemed. Please contact ${partnerName} for further assistance.`
                    : 'Uh oh! Looks like something is wrong! try again'
                  setStatus('error')
                  setFieldError('customerKey', message)
                  setSubmitting(false)
                },
                (res: CreatePartnerOrderResponse) => {
                  setStatus('success')
                  res.token &&
                    res.sid &&
                    res.orderId &&
                    forwardPreactivationFlowToWebApp(res)
                }
              )
            )
          })
        }}
        validationSchema={
          locale === 'en-US' ? validationSchemaUS : validationSchemaUK
        }
      >
        {({ isValid, isSubmitting, errors, status }) =>
          status !== 'success' ? (
            <Form className="w-full " noValidate>
              <h4 className="mt-0 not-prose">Shipping Information</h4>
              <div className="grid grid-cols-12 gap-4">
                {status
                  ? fields.map(field => (
                      <FormField
                        className={classNames('col-span-12 ', {
                          'md:col-span-4':
                            field.name === 'state' ||
                            field.name === 'postalCode' ||
                            field.name === 'city' ||
                            field.name === 'country',
                          'md:col-span-6':
                            field.name === 'address1' ||
                            field.name === 'address2' ||
                            field.name === 'phone' ||
                            field.name === 'email' ||
                            field.name === 'firstName' ||
                            field.name === 'lastName'
                        })}
                        id={field.name}
                        key={field.name}
                        label={field.placeholder}
                        labelClassName="hidden"
                      >
                        {match(field.type)
                          .with('Text', () => (
                            <FormTextInput
                              id={field.name}
                              name={field.name}
                              placeholder={field.placeholder}
                            />
                          ))
                          .with('Email', () => (
                            <FormTextInput
                              id={field.name}
                              name={field.name}
                              placeholder={field.placeholder}
                              type="email"
                            />
                          ))
                          .with('Dropdown', () => (
                            <FormSelect
                              name={field.name}
                              options={
                                locale === 'en-US'
                                  ? usStates
                                  : ['United Kingdom']
                              }
                              placeholder={field.placeholder}
                            />
                          ))
                          .with('Telephone', () => (
                            <FormTextInput
                              id={field.name}
                              name={field.name}
                              placeholder={field.placeholder}
                              type="text"
                            />
                          ))
                          .exhaustive()}
                      </FormField>
                    ))
                  : null}
              </div>
              <button
                className={classNames(
                  'btn mb-2 mt-2 block h-14 w-fit min-w-[190px] px-8 md:mt-8 lg:mb-4 lg:mt-4',
                  {
                    'bg-neutral-medium-100 border-none text-white': !isValid,
                    'btn-solid-primary cursor-pointer': isValid
                  }
                )}
                disabled={!isValid || isSubmitting}
                name="submit"
                type="submit"
              >
                {isSubmitting ? <Spinner /> : button.text}
              </button>
              {errors.customerKey ? (
                <p className="text-sale my-2">{errors.customerKey}</p>
              ) : null}
              <div>
                {note ? (
                  <p className="m-0 w-full text-xs text-[var(--neutral-dark-100)] prose-p:m-0 prose-p:text-neutral-dark">
                    <ContentfulRichText raw={note} />
                  </p>
                ) : null}
              </div>
              <TermsOfSale />
            </Form>
          ) : (
            <div className="text-sale mt-0">
              <h3 className="mt-0">
                Thank you for submitting your information
              </h3>
              <p className="mb-0">
                You should receive an email in the next few days confirming your
                order has been shipped. When your new SimpliSafe system arrives,
                don&apos;t forget to set it up and activate it.
              </p>
            </div>
          )
        }
      </Formik>
    </div>
  )
}
