import {
  FAST_PROTECT_THREE_MONTHS_OFFER,
  INTERACTIVE_MONITORING,
  ODMON_PREBUILT_PACKAGES
} from '@ecomm/data-constants'
import { PriceProvider } from '@ecomm/data-price'
import { Footer } from '@ecomm/footer-components'
import { useFooterQuery } from '@ecomm/footer-data'
import { Breadcrumbs, Header as HeaderRedesign } from '@ecomm/header-redesigned'
import { useMicroCopy } from '@ecomm/micro-copy'
import {
  ApplyPromoCode,
  PageToaster,
  PromoBannerWrapper
} from '@ecomm/promotions-components'
import { useIsVariationOfExperiment } from '@ecomm/promotions-hooks'
import {
  Header as SharedHeader,
  toPromoBannerStyleValue
} from '@ecomm/shared-components'
import { getValueFromPartnerCookie } from '@ecomm/shared-cookies'
import { useCartQuantity } from '@ecomm/shared-hooks'
import { mapExperiences } from '@ecomm/shared-ninetailed'
import { Experience } from '@ecomm/shared-ninetailed-experience'
import { useOdmonExperience } from '@ecomm/shared-ninetailed-odmon'
import {
  type FeatureSectionProps,
  FeatureSection
} from '@ecomm/shared-sections'
import { PageWrapper, Text as Typography } from '@ecomm/ss-react-components'
import { TrackingProvider } from '@ecomm/tracking'
import { type Locale, SEO } from '@ecomm/utils'
import { useLocation } from '@reach/router'
import type { PriceData } from '@simplisafe/ss-ecomm-data/prices/service'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'
import { lookup } from 'fp-ts/lib/Record'
import { type PageProps, graphql } from 'gatsby'
import { Suspense, useEffect } from 'react'
import { match } from 'ts-pattern'

import CarouselSection from '../../components/CarouselSection'
import Floor from '../../components/FloorPlan'
import Header from '../../components/Header'
import Package from '../../components/Package'
import AffirmPackageWrapper from '../../components/Package/AffirmPackageWrapper'
import useExistsOutdoorCameraInPkg from '../../components/Package/hooks/useExistsOutdoorCameraInPkg'
import { useOdmonPDPQuery } from '../../components/Package/hooks/useOdmonPDPQuery'
import { type PackageType, hideMap } from '../../components/Package/schema'
import ReviewSection from '../../components/ReviewSection'
import type { SeoNodeSchema } from '../../config/seoNodeSchema'
import { ProductProvider } from '../../contexts/ProductContext'
import { useAffirmExperimentQuery } from '../../experiments/AffirmAPR/useAffirmExperimentQuery'
import { usePromoBannerExperimentQuery } from '../../experiments/PromoBannerPhoneNumber/usePromoBannerExperimentQuery'
import { useResetDraftCart } from '../../hooks/draftCart/useResetDraftCart'
import { useHeaderRedesignQuery } from '../../hooks/HeaderRedesign/useHeaderRedesignQuery'
import type { DynamicPackage } from '../../utils/DynamicPackages/types'
import { aggregateProducts } from '../../utils/productAggregator'
import { useOdmonPLPQuery } from '../Plp/useOdmonPLPQuery'
import { useOdmonRefurbishedPLPQuery } from '../Plp/useOdmonRefurbishedPLPQuery'
import type { ComponentTypes } from './pdpPageSchema'
import { usePdpPageFragment } from './usePdpPageFragment'

export type PageContext = {
  readonly locale: Locale
  readonly seoDetails: SeoNodeSchema
  readonly packageSku: string
  readonly packageType: PackageType
  readonly dynamicPackage: DynamicPackage
}

export type SSRResponse = {
  readonly price: O.Option<PriceData>
}

type Props = Pick<
  PageProps<unknown, PageContext, unknown>,
  'data' | 'pageContext' | 'params'
>

export const getMappedComponent = (component: ComponentTypes, index: number) =>
  match(component)
    .with({ __typename: 'ContentfulReviewSection' }, data =>
      O.some(<ReviewSection data={data} key={index} />)
    )
    .with(
      { __typename: 'ContentfulFeatureSection' },
      (data: FeatureSectionProps) =>
        O.some(
          <Typography key={index} useTailwind>
            <FeatureSection {...data} />
          </Typography>
        )
    )
    .with({ __typename: 'ContentfulCarousel' }, data =>
      O.some(<CarouselSection data={data} key={index} />)
    )
    .exhaustive()

export const mapPageComponents = (components: readonly ComponentTypes[]) =>
  components.map((componentData, index) =>
    pipe(getMappedComponent(componentData, index), O.toNullable)
  )

function Content({
  data,
  pageContext: { locale, seoDetails, dynamicPackage },
  params
}: Props) {
  //@ts-expect-error
  const pdpPage = usePdpPageFragment(data)
  const affirmFeatureFlagQueryData = useAffirmExperimentQuery()

  const microcopy = useMicroCopy()
  const { cartQuantity: cartItemCount } = useCartQuantity()

  const { layout, package: pkgCtfl } = pdpPage

  const {
    header,
    footer: { contentful_id: footerId },
    components
  } = layout

  const footer = useFooterQuery(footerId)

  const {
    metaTitle,
    metaDescription,
    metaKeywords,
    isNofollow,
    isNoindex,
    canonicalLink
  } = seoDetails

  const { personalizePackage, products: pkgProducts } = pkgCtfl

  const location = useLocation()

  const productSkus = pkgProducts.map(product => product.sku)

  const personalizePackageSkus = pkgCtfl.personalizePackage.items.map(
    item => item.sku
  )

  const skus: readonly string[] = [
    pkgCtfl.product,
    dynamicPackage.sku,
    ...productSkus,
    ...personalizePackageSkus
  ]

  const bannerStyle = toPromoBannerStyleValue(layout.promoBannerStyle)

  const products = aggregateProducts({ sensorGroups: [personalizePackage] })

  // Hash used to derive a dynamic package if supplied.
  const attributeHash = pipe(
    O.fromNullable(params),
    O.chain(lookup('payload')),
    O.getOrElse(() => '')
  )

  const shouldShowFloorPlanForThisPackageType = !hideMap[pkgCtfl.type].floorPlan

  // Force wipe Draft Cart state when leaving PDP page.
  // TODO: This functionality can be removed when Jotai Providers are
  // template scoped: https://simplisafe.atlassian.net/browse/ECP-8723
  const wipeDraftCart = useResetDraftCart()
  useEffect(() => {
    return () => {
      wipeDraftCart()
    }
  }, [])

  /**
   * 3 free months monitoring experiment
   */
  const isThreeMonthsMonitoringExperiment = useIsVariationOfExperiment(
    'all___us___promo___three_months_offer'
  )

  const monitoringToggles = pkgCtfl.monitoringToggles.map(mt => {
    return isThreeMonthsMonitoringExperiment &&
      mt.sku === INTERACTIVE_MONITORING
      ? { ...mt, sku: FAST_PROTECT_THREE_MONTHS_OFFER }
      : mt
  })

  const headerData = useHeaderRedesignQuery()

  const headerComponent =
    header &&
    (pkgCtfl.type !== 'PLA' ? (
      // @ts-expect-error
      <Header data={header} />
    ) : (
      <SharedHeader
        {...header}
        ctaMenu={{
          cartQuantity: cartItemCount,
          showShopCTA: true,
          quoteWizard: {
            ...header.quoteWizard,
            // @ts-expect-error
            label: 'build a system'
          }
        }}
        type="pla"
      >
        <p className="m-0 mx-auto hidden font-bold text-white lg:block">
          {microcopy['60-day-money-back']}
        </p>
      </SharedHeader>
    ))

  const isStrategicReferralPartner =
    getValueFromPartnerCookie('partnerGroup') === 'partner-strategic-referral'

  const promoBannerExperiment = usePromoBannerExperimentQuery()

  /* ODMON Experiment - PDP Components + product */
  const existsOutdoorCamera = useExistsOutdoorCameraInPkg()
  const isOdmonVariant = useOdmonExperience().isVariant
  const isOdmonPackage =
    ODMON_PREBUILT_PACKAGES.includes(pkgCtfl.sku) || existsOutdoorCamera
  const odmonComponents = useOdmonPDPQuery()
  const odmonPlp = useOdmonPLPQuery()
  const odmonRefurbishedPlp = useOdmonRefurbishedPLPQuery()

  enum mapPackageType {
    prebuilt = 'Prebuilt',
    refurb = 'Refurbished',
    custom = 'Dynamic',
    pla = 'PLA'
  }

  // Merge CTFL + products-api package
  const unifiedPkg = {
    ...pkgCtfl,
    monitoringToggles,
    displayName: dynamicPackage.name,
    product: dynamicPackage.sku,
    // @ts-expect-error - TODO - not sure how this was merged on main, but this is a string and can't be used as an index
    type: mapPackageType[dynamicPackage.type],
    packageProducts: dynamicPackage.components
      .filter(comp => !comp.includedInPackage)
      .map(comp => ({
        quantity: comp.defaultQuantity,
        sku: comp.sku
      }))
  }

  const packageVariant =
    [...odmonPlp.packages, ...odmonRefurbishedPlp.packages].find(
      pkgItem => pkgCtfl.sku === pkgItem.sku
    ) || unifiedPkg

  const pkgExperience = isOdmonVariant
    ? { ...unifiedPkg, ...packageVariant, product: packageVariant.sku }
    : unifiedPkg

  return (
    <TrackingProvider metaTitle={metaTitle}>
      <PageToaster />
      <ApplyPromoCode />
      <SEO
        canonicalLink={canonicalLink || ''}
        isNofollow={isNofollow}
        isNoindex={isNoindex}
        lang={locale}
        metaDescription={metaDescription.metaDescription}
        metaKeywords={metaKeywords || []}
        metaTitle={metaTitle}
      />
      <ProductProvider products={products}>
        <PriceProvider locale={locale} skus={skus}>
          <PageWrapper>
            <PromoBannerWrapper
              experimentData={promoBannerExperiment}
              // @ts-expect-error
              type={bannerStyle}
            />
            {locale === 'en-GB' ? (
              headerComponent
            ) : (
              <HeaderRedesign type="full" {...headerData} />
            )}
            {isStrategicReferralPartner ? null : (
              <Breadcrumbs
                steps={[
                  {
                    label: pdpPage.breadcrumbTitle || pkgCtfl.displayName,
                    slug: location.pathname.replace('/', '')
                  }
                ]}
                template="PDP"
                type={pkgCtfl.type}
              />
            )}
            <div className="max-w-8xl mx-auto mb-16 flex flex-col gap-16 p-4 md:p-8">
              {locale === 'en-US' ? (
                <Experience
                  {...affirmFeatureFlagQueryData}
                  component={AffirmPackageWrapper}
                  // @ts-expect-error
                  experiences={mapExperiences<AffirmFeatureFlagSchema>(
                    affirmFeatureFlagQueryData.nt_experiences
                  )}
                  passthroughProps={{
                    attributeHash: attributeHash,
                    metaDescription: metaDescription.metaDescription,
                    metaTitle: metaTitle,
                    pkg: pkgExperience
                  }}
                />
              ) : (
                <Package
                  attributeHash={attributeHash}
                  isAffirmExperience={false}
                  metaDescription={metaDescription.metaDescription}
                  metaTitle={metaTitle}
                  pkg={unifiedPkg}
                />
              )}
              {shouldShowFloorPlanForThisPackageType ? (
                <Floor
                  packageName={pkgExperience.displayName}
                  packageType={pkgExperience.type}
                  products={pkgExperience.products}
                />
              ) : null}
              {isOdmonVariant && isOdmonPackage
                ? mapPageComponents(odmonComponents)
                : null}
              {components ? mapPageComponents(components) : null}
            </div>
            <Footer {...footer} type="Full" />
          </PageWrapper>
        </PriceProvider>
      </ProductProvider>
    </TrackingProvider>
  )
}

export default function PDPTemplate(props: Props) {
  return (
    <Suspense>
      <Content {...props} />
    </Suspense>
  )
}

export const pdpTemplateQuery = graphql`
  #graphql
  query PdpTemplate($id: String) {
    contentfulPackageDetailPage(id: { eq: $id }) {
      ...pdpPage
      ...pdpPageVariations
    }
  }
`
