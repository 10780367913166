import {
  bmsToRudderstackProducts,
  useTrackAddProductToCart
} from '@ecomm/cdp-tracking-utils'
import {
  FAST_PROTECT_THREE_MONTHS_OFFER,
  INTERACTIVE_MONITORING,
  ODMON_247_MONITORING,
  ODMON_OVERNIGHT_MONITORING
} from '@ecomm/data-constants'
import { getCartId } from '@ecomm/data-storage'
import { useIsVariationOfExperiment } from '@ecomm/promotions-hooks'
import { getPartnerCookie } from '@ecomm/shared-cookies'
import { LoadingSpinner, SimpleButton } from '@ecomm/ss-react-components'
import type { TrackingData } from '@ecomm/tracking'
import {
  trackBmsAddToCartFailure,
  trackBmsAddToCartSuccess,
  useOptimizelyTrackSiteEvents,
  useTrackATCPlanSelection
} from '@ecomm/tracking'
import { Locale } from '@ecomm/utils'
import { localStorage } from '@simplisafe/ewok'
import {
  IOAddBmsToCart,
  IOCreateOrSetPartnerAssociationCart
} from '@simplisafe/ss-ecomm-data/cart'
import { ImmutableCart } from '@simplisafe/ss-ecomm-data/commercetools/cart'
import { useAtom } from 'jotai'
import { Dispatch, SetStateAction, useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTracking } from 'react-tracking'

import { itemQuantityAtom } from '../../../atoms/draftCart/itemQuantityAtom'
import {
  DraftCartMonitoring,
  monitoringAtom
} from '../../../atoms/draftCart/monitoringPlanAtom'
import { totalPriceAtom } from '../../../atoms/draftCart/totalPriceAtom'
import { useProductContext } from '../../../contexts/ProductContext'
import { handleAddToCartRedirection } from '../../AddToCartButton/handleAddToCartRedirection'
import { BundleFragment, ButtonFragment, MonitoringPlan } from '../types'

export type Props = {
  readonly button: ButtonFragment
  readonly isValidCart: boolean
  readonly isOdmonVariant?: boolean
  readonly setError: Dispatch<SetStateAction<string>>
  readonly defaultBundle: BundleFragment
  readonly locale: Locale
}

const SUBMISSION_ERROR = 'submission-error'

export const getMonitoringAddToCartData = ({
  monitoring,
  isThreeMonthsMonitoringExperiment
}: {
  readonly monitoring: DraftCartMonitoring
  readonly isThreeMonthsMonitoringExperiment: boolean
}) => {
  switch (monitoring.type) {
    case MonitoringPlan.interactive:
      return {
        name: 'Fast Protect™ Monitoring',
        quantity: 1,
        sku:
          isThreeMonthsMonitoringExperiment &&
          monitoring.plan === INTERACTIVE_MONITORING
            ? FAST_PROTECT_THREE_MONTHS_OFFER
            : monitoring.plan
      }
    case MonitoringPlan.odmonOvernight:
      return {
        name: 'Pro Monitoring',
        quantity: 1,
        sku: ODMON_OVERNIGHT_MONITORING
      }
    case MonitoringPlan.odmon247:
      return {
        name: 'Pro Plus Monitoring',
        quantity: 1,
        sku: ODMON_247_MONITORING
      }
    default:
      return null
  }
}

function DraftCartAddToCartButton({
  button,
  isValidCart,
  isOdmonVariant = false,
  setError,
  defaultBundle,
  locale
}: Props) {
  const trackAddToCart = useTrackATCPlanSelection()
  const isUs = locale === 'en-US'

  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()

  const { trackEvent } = useTracking<TrackingData>()
  const optimizelyTrackSiteEvents = useOptimizelyTrackSiteEvents()
  const trackAddToCartSuccess = trackBmsAddToCartSuccess(
    trackEvent,
    optimizelyTrackSiteEvents
  )
  const trackAddToCartFailure = trackBmsAddToCartFailure(
    optimizelyTrackSiteEvents
  )

  const { trackAddProductToCartEvent } = useTrackAddProductToCart()
  const partnerData = getPartnerCookie()

  const [items] = useAtom(itemQuantityAtom)
  const [priceTotals] = useAtom(totalPriceAtom)
  const [monitoring] = useAtom(monitoringAtom)

  /**
   * 3 free months monitoring experiment
   */
  const isThreeMonthsMonitoringExperiment = useIsVariationOfExperiment(
    'all___us___promo___three_months_offer'
  )

  const { getProduct } = useProductContext()

  const draftCartProducts = items.toArray().map(([sku, quantity]) => ({
    name: getProduct(sku)?.name || '',
    quantity,
    sku
  }))

  const monitoringAddToCartData = getMonitoringAddToCartData({
    monitoring,
    isThreeMonthsMonitoringExperiment
  })

  const products = monitoringAddToCartData
    ? draftCartProducts.concat(monitoringAddToCartData)
    : draftCartProducts

  const addToCart = useCallback(() => {
    const isUS = locale === 'en-US'

    // US + interactive or ss2 = /cart
    // US + none = /choose-monitoring
    // UK + interactive = /choose-monitoring2
    // UK + none = /choose-monitoring2
    const url = isUS
      ? monitoring.type === MonitoringPlan.interactive ||
        monitoring.type === MonitoringPlan.ss2 ||
        monitoring.type === MonitoringPlan.odmonOvernight ||
        monitoring.type === MonitoringPlan.odmon247
        ? '/cart'
        : isOdmonVariant
          ? '/choose-monitoring-3'
          : '/choose-monitoring'
      : '/choose-monitoring2'
    setLoading(true)

    trackAddToCart(monitoring.plan, 'bms')

    const onSuccess = (cartResponse: ImmutableCart | void) => {
      setLoading(false)

      // GTM
      trackAddToCartSuccess(
        locale,
        defaultBundle,
        products,
        priceTotals.discountedTotal || priceTotals.total
      )

      // Rudderstack
      trackAddProductToCartEvent({
        locale,
        total: priceTotals.discountedTotal ?? priceTotals.total,
        products: bmsToRudderstackProducts({
          productSku: defaultBundle.baseProduct,
          includedProducts: defaultBundle.products,
          components: products,
          totalPrice: priceTotals.discountedTotal ?? priceTotals.total
        })
      })

      // Take the last package_parent_id, which will be a reference to this most recently
      // added BMS package.
      const parentId = (cartResponse?.lineItems || []).reduce((acc, item) => {
        const packageParentId = item.custom?.fields?.package_parent_id
        return packageParentId || acc
      }, '')

      // We save the package parent id in local storage, which then gets read during
      // change monitoring plan flows
      parentId && localStorage.set('parentId', parentId)

      handleAddToCartRedirection(url, defaultBundle.baseProduct)
    }

    const onFailure = () => {
      setLoading(false)
      setError(SUBMISSION_ERROR)
      trackAddToCartFailure()
    }

    dispatch(
      IOAddBmsToCart(
        {
          package: {
            quantity: 1,
            sku: defaultBundle.baseProduct
          },
          products: products
            .filter(({ quantity }) => quantity !== 0)
            .map(({ quantity, sku }) => ({
              quantity,
              sku
            }))
        },
        onFailure,
        onSuccess
      )
    )

    const cartId = getCartId() || ''
    partnerData?.partnerName &&
      dispatch(
        IOCreateOrSetPartnerAssociationCart(
          partnerData.partnerGroup,
          partnerData.partnerName,
          cartId,
          onFailure
        )
      )
  }, [
    button.url,
    defaultBundle,
    dispatch,
    locale,
    priceTotals,
    monitoring,
    setError,
    products,
    trackAddToCartFailure,
    trackAddToCartSuccess
  ])

  return (
    <SimpleButton
      className={isUs ? 'w-80' : 'w-40 min-w-max'}
      disabled={!isValidCart}
      onClick={addToCart}
      variant="solid"
    >
      {loading ? (
        <LoadingSpinner
          className="fill-complementary-blue-100 text-complementary-blue-100"
          size={24}
        />
      ) : (
        <>
          {button.text}
          <span className="sr-only">(opens in new tab)</span>
        </>
      )}
    </SimpleButton>
  )
}

export default DraftCartAddToCartButton
