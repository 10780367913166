import { LoadingSpinner } from '@ecomm/checkout-icons'
import { useLocale } from '@ecomm/data-hooks'
import { useTrackMetricEvent } from '@ecomm/tracking'
import { RemoteData } from '@simplisafe/ss-ecomm-data/RemoteData'
import { ImmutableCart } from '@simplisafe/ss-ecomm-data/commercetools/cart'
import { selectCart } from '@simplisafe/ss-ecomm-data/redux/select'
import { ServerError } from '@simplisafe/ss-ecomm-data/thirdparty/awin'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'

import {
  CartRetrievalErrorMessage,
  handlePaymentPageView,
  handleShippingPageView
} from '../utils'
import { useRenderOrderSummary } from './useRenderOrderSummary'

export function OrderSummaryContainer() {
  const locale = useLocale()

  const cart: RemoteData<ImmutableCart> = useSelector(selectCart)
  const renderOrderSummary = useRenderOrderSummary()
  const { Track, trackEvent } = useTracking()
  const [hasViewedShippingPage, setHasViewedShippingPage] = useState(false)
  const [hasViewedPaymentPage, setHasViewedPaymentPage] = useState(false)

  const trackMetricEvent = useTrackMetricEvent()
  useEffect(() => {
    const checkShippingPage = () => {
      !hasViewedShippingPage &&
        window.location.pathname === '/cart/checkout' &&
        handleShippingPageView(
          cart,
          trackEvent,
          setHasViewedShippingPage,
          setHasViewedPaymentPage,
          trackMetricEvent
        )
    }

    const checkPaymentPage = () => {
      !hasViewedPaymentPage &&
        window.location.pathname === '/payment-page' &&
        handlePaymentPageView(
          cart,
          trackEvent,
          setHasViewedShippingPage,
          setHasViewedPaymentPage,
          trackMetricEvent
        )
    }

    checkShippingPage()
    checkPaymentPage()
  }, [
    cart,
    hasViewedPaymentPage,
    hasViewedShippingPage,
    trackEvent,
    trackMetricEvent
  ])

  return (
    <Track>
      <div data-component="OrderSummaryContainer">
        {cart.cata(
          /*
          Rather than just returning a Loading state, we display the order summary if the cart has loaded.
          If the cart ever errors, the error state will show.
          When a user updates their State, Postal Code, or discount codes, the order summary
          will refresh without showing the user an error state.
          */
          () =>
            cart
              .map(_cart => renderOrderSummary(_cart))
              .orJust(
                <div className="alignCenter">
                  <h2>Loading your cart...</h2>
                  <LoadingSpinner fillColor="#f79e00" size={48} />
                </div>
              ),
          // cart error state
          (error: ServerError) => {
            trackMetricEvent('cart-render-error', {
              shippingViewed: String(hasViewedShippingPage),
              paymentViewed: String(hasViewedPaymentPage),
              error: error.statusText ?? ''
            })

            return (
              <div className="alignCenter">
                {<CartRetrievalErrorMessage locale={locale} />}
              </div>
            )
          },
          // show cart loading state while attempting to populating cart data
          () => (
            <div className="alignCenter">
              <h2>Loading your cart...</h2>
              <LoadingSpinner fillColor="#f79e00" size={48} />
            </div>
          ),
          // cart value state -- executes when something is in a user's cart
          (x: ImmutableCart) => renderOrderSummary(x)
        )}
      </div>
    </Track>
  )
}
