import { createMockImage } from '@ecomm/test-utils'
import { IconDropDown } from '@ecomm/ss-react-components'
import { useEnv } from '@ecomm/utils'

import { getCountryInformation } from './helpers'
import type { Countries } from '@ecomm/footer-data'

type Props = {
  readonly className?: string
}

//TODO: Move this data to a more appropiate place after releasing the new version of BMS
export const countries: Countries = [
  {
    linkIcon: createMockImage(
      '//images.ctfassets.net/br4ichkdqihc/7ura6edmMqhbAKdBa3yuMv/5d0ab7ef73e09461dc2caf5f5f562077/uk-flag.png?w=5&h=5&q=50 5w,\n//images.ctfassets.net/br4ichkdqihc/7ura6edmMqhbAKdBa3yuMv/5d0ab7ef73e09461dc2caf5f5f562077/uk-flag.png?w=10&h=10&q=50 10w,\n//images.ctfassets.net/br4ichkdqihc/7ura6edmMqhbAKdBa3yuMv/5d0ab7ef73e09461dc2caf5f5f562077/uk-flag.png?w=20&h=20&q=50 20w,\n//images.ctfassets.net/br4ichkdqihc/7ura6edmMqhbAKdBa3yuMv/5d0ab7ef73e09461dc2caf5f5f562077/uk-flag.png?w=30&h=30&q=50 30w,\n//images.ctfassets.net/br4ichkdqihc/7ura6edmMqhbAKdBa3yuMv/5d0ab7ef73e09461dc2caf5f5f562077/uk-flag.png?w=40&h=40&q=50 40w,\n//images.ctfassets.net/br4ichkdqihc/7ura6edmMqhbAKdBa3yuMv/5d0ab7ef73e09461dc2caf5f5f562077/uk-flag.png?w=60&h=60&q=50 60w'
    ),
    linkSubtext: 'UK',
    linkText: 'United Kingdom',
    linkUrl: 'https://simplisafe.co.uk'
  },
  {
    linkIcon: createMockImage(
      '//images.ctfassets.net/br4ichkdqihc/8aTUF1NMqCWP2paOkKc7N/b11d8c93d31ccdf83243eb0f966197c3/Flag_-_USA.png?w=5&h=5&q=50 5w,\n//images.ctfassets.net/br4ichkdqihc/8aTUF1NMqCWP2paOkKc7N/b11d8c93d31ccdf83243eb0f966197c3/Flag_-_USA.png?w=10&h=10&q=50 10w,\n//images.ctfassets.net/br4ichkdqihc/8aTUF1NMqCWP2paOkKc7N/b11d8c93d31ccdf83243eb0f966197c3/Flag_-_USA.png?w=20&h=20&q=50 20w,\n//images.ctfassets.net/br4ichkdqihc/8aTUF1NMqCWP2paOkKc7N/b11d8c93d31ccdf83243eb0f966197c3/Flag_-_USA.png?w=30&h=30&q=50 30w,\n//images.ctfassets.net/br4ichkdqihc/8aTUF1NMqCWP2paOkKc7N/b11d8c93d31ccdf83243eb0f966197c3/Flag_-_USA.png?w=40&h=40&q=50 40w,\n//images.ctfassets.net/br4ichkdqihc/8aTUF1NMqCWP2paOkKc7N/b11d8c93d31ccdf83243eb0f966197c3/Flag_-_USA.png?w=60&h=60&q=50 60w'
    ),
    linkSubtext: 'USA',
    linkText: 'United States',
    linkUrl: 'https://simplisafe.com'
  }
]

export function CountrySelector({ className = '' }: Props) {
  const { locale } = useEnv()

  return (
    <div className={className}>
      <IconDropDown {...getCountryInformation(countries, locale)} />
    </div>
  )
}
