import {
  INTERACTIVE_MONITORING,
  PRO_PREMIUM_MONITORING_UK
} from '@ecomm/data-constants'
import { PriceProvider } from '@ecomm/data-price'
import { Footer } from '@ecomm/footer-components'
import { useFooterQuery } from '@ecomm/footer-data'
import {
  ContentfulRichText,
  CountryRedirectModal,
  ShowMore
} from '@ecomm/shared-components'
import { ColoredSection } from '@ecomm/shared-sections'
import { TrackingProvider } from '@ecomm/tracking'
import { type Locale, SEO } from '@ecomm/utils'
import { type PageProps, graphql } from 'gatsby'
import { Suspense } from 'react'
import { match } from 'ts-pattern'

import { EmbeddedPartnerForm } from '../components/EmbeddedPartnerForm'
import { EmbeddedPartnerHeader } from '../components/EmbeddedPartnerHeader'
import { EmbeddedPartnerPackage } from '../components/EmbeddedPartnerPackage'
import { FloorPlanCarouselWrapper } from '../components/FloorPlanCarouselWrapper '
import { MonitoringWrapper } from '../components/Monitoring'
import { MonitoringWrapperUK } from '../components/Monitoring/MonitoringUK'
import { embeddedPartnerPageSchema } from '../config/schema'

export type PageContext = {
  readonly locale: Locale
}

export type Props = Pick<
  PageProps<unknown, PageContext>,
  'data' | 'pageContext'
>

function Content({ data, pageContext: { locale } }: Props) {
  const {
    pageTitle,
    layout: {
      footer: { contentful_id: footerId },
      header,
      components
    },
    seo,
    partnerName
  } = embeddedPartnerPageSchema.parse(data).contentfulEmbeddedPartnerPage

  const footer = useFooterQuery(footerId)

  const colOrder = locale === 'en-GB' ? ' order-2' : ''

  return (
    <TrackingProvider metaTitle={pageTitle}>
      <PriceProvider
        locale={locale}
        skus={[INTERACTIVE_MONITORING, PRO_PREMIUM_MONITORING_UK]}
      >
        <SEO
          isNofollow={seo.isNofollow}
          isNoindex={seo.isNoindex}
          lang={locale}
          metaDescription={seo.metaDescription?.metaDescription || ''}
          metaTitle={seo.metaTitle}
        />
        <CountryRedirectModal />
        <EmbeddedPartnerHeader {...header} />
        <div className="relative z-0 w-full whitespace-pre-line">
          <div className="max-w-8xl mx-auto mb-16 flex flex-col gap-5 px-4 pt-8 md:px-8">
            {components.map(component =>
              match(component)
                .with(
                  { __typename: 'ContentfulTwoColumnSection' },
                  ({ rightSide, leftSide }) => (
                    <div className="flex flex-wrap">
                      <div
                        className={
                          'paragraph w-full px-3 lg:w-1/2 lg:px-7 prose-h1:text-complementary-red-100 prose-headings:inline prose-headings:m-0 prose-headings:text-5xl' +
                          colOrder
                        }
                      >
                        {leftSide.content.map((content, index) =>
                          match(content)
                            .with(
                              { __typename: 'ContentfulSmallText' },
                              ({ text }) => (
                                <ContentfulRichText
                                  key={index}
                                  raw={text.raw}
                                />
                              )
                            )
                            .with(
                              { __typename: 'ContentfulFormV2' },
                              ({ button, fields, note }) => (
                                <EmbeddedPartnerForm
                                  button={button}
                                  fields={fields}
                                  note={note?.raw}
                                  partnerName={partnerName}
                                />
                              )
                            )
                            .exhaustive()
                        )}
                      </div>
                      <div className="w-full px-3 lg:w-1/2 lg:px-7">
                        <EmbeddedPartnerPackage {...rightSide} />
                      </div>
                    </div>
                  )
                )
                .with(
                  { __typename: 'ContentfulCarousel' },
                  ({ items, title, __typename }) => (
                    <FloorPlanCarouselWrapper
                      {...{ items, title, __typename }}
                    />
                  )
                )
                .with(
                  { __typename: 'ContentfulLayoutReference' },
                  ({ referenceId }) =>
                    match(referenceId)
                      .with('Monitoring', () =>
                        locale === 'en-US' ? (
                          <MonitoringWrapper />
                        ) : (
                          <MonitoringWrapperUK />
                        )
                      )
                      .otherwise(() => null)
                )
                .with({ __typename: 'ContentfulSmallText' }, ({ text }) =>
                  locale === 'en-GB' ? (
                    <ShowMore hiddenClasses="lg:max-h-36 md:max-h-[205px] max-h-80 overflow-hidden">
                      <ContentfulRichText raw={text.raw} />
                    </ShowMore>
                  ) : (
                    <ContentfulRichText raw={text.raw} />
                  )
                )
                .with({ __typename: 'ContentfulColoredSection' }, data => (
                  <div className="prose-p:my-0 prose-p:md:mx-auto prose-p:md:w-2/3 prose-h2:mt-0">
                    <ColoredSection {...data} key="colored-section-key" />
                  </div>
                ))
                .exhaustive()
            )}
          </div>
        </div>

        <Footer {...footer} type={footer.type} />
      </PriceProvider>
    </TrackingProvider>
  )
}

export default function EmbeddedPartnerPageTemplate(props: Props) {
  return (
    <Suspense>
      <Content {...props} />
    </Suspense>
  )
}
export const query = graphql`
  query embeddedPartnerPageTemplate($id: String) {
    contentfulEmbeddedPartnerPage(id: { eq: $id }) {
      pageTitle
      partnerName
      seo {
        metaKeywords
        metaTitle
        isNoindex: noIndex
        isNofollow: noFollow
        canonicalLink
        metaDescription {
          metaDescription
        }
      }
      layout {
        header {
          ...embeddedPartnerHeader
        }
        footer {
          contentful_id
        }
        components {
          ... on ContentfulTwoColumnSection {
            __typename
            rightSide {
              ... on ContentfulContentCollection {
                content {
                  ... on ContentfulCarousel {
                    __typename
                    items {
                      image {
                        description
                        url
                        originalWidth: width
                        originalHeight: height
                      }
                    }
                  }
                  ... on ContentfulSmallText {
                    text {
                      raw
                      references {
                        ... on ContentfulPlaceholder {
                          __typename
                          type
                        }
                      }
                    }
                  }
                }
              }
            }
            leftSide {
              ... on ContentfulContentCollection {
                content {
                  ... on ContentfulSmallText {
                    text {
                      raw
                      references {
                        ... on ContentfulPlaceholder {
                          __typename
                          type
                        }
                      }
                    }
                  }
                  ...embeddedPartnerForm
                }
              }
            }
          }
          ... on ContentfulCarousel {
            title
            __typename
            items {
              headline
              description {
                raw
              }
              image {
                description
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          ... on ContentfulLayoutReference {
            __typename
            referenceId
          }
          ... on ContentfulSmallText {
            __typename
            text {
              raw
            }
          }
          ... on ContentfulColoredSection {
            __typename
            contentful_id
            headline
            content {
              ... on ContentfulSmallText {
                contentful_id
                text {
                  raw
                }
              }
            }
            theme
          }
        }
      }
    }
  }
`
