import { Footer } from '@ecomm/footer-components'
import { Breadcrumbs, Header } from '@ecomm/header-redesigned'
import { LegalRichText, SubmitYourIdeasForm } from '@ecomm/legal-components'
import { LEGAL_TEMPLATE_QUERY, legalTextPageSchema } from '@ecomm/legal-data'
import { PromoBannerWrapper } from '@ecomm/promotions-components'
import { useContentful } from '@ecomm/shared-apollo'
import {
  toPromoBannerStyleValue,
  TrustpilotUKTopBanner
} from '@ecomm/shared-components'
import { TrackingProvider } from '@ecomm/tracking'
import { SEO } from '@ecomm/utils'
import { type PageProps, graphql } from 'gatsby'
import { Suspense, useMemo } from 'react'

import type { LegalResponseNode } from '../config/legalResponseSchema'
import { usePromoBannerExperimentQuery } from '../experiments/PromoBannerPhoneNumber/usePromoBannerExperimentQuery'
import { useHeaderRedesignQuery } from '../hooks/useHeaderRedesignQuery'
import { useLegalLayout } from '../hooks/useLegalLayout'

export type PageContext = {
  readonly locale: string
  readonly seoDetails: LegalResponseNode['seoDetails']
  readonly slug: LegalResponseNode['slug']
}

type Props = Partial<PageProps> & {
  readonly data: {
    readonly contentfulTextPage: {
      readonly slug: string
      readonly layout?: {
        readonly contentful_id?: string
      }
    }
  }
  readonly pageContext: PageContext
}

export function Content({ data, pageContext: { locale, seoDetails } }: Props) {
  const { footer, promoBannerStyle } = useLegalLayout(
    data.contentfulTextPage.layout?.contentful_id
  )
  const headerData = useHeaderRedesignQuery()
  const promoBannerExperiment = usePromoBannerExperimentQuery()
  const promoType = toPromoBannerStyleValue(promoBannerStyle) ?? 'none'

  const {
    metaTitle,
    metaDescription,
    metaKeywords,
    isNofollow,
    isNoindex,
    canonicalLink
  } = seoDetails

  const slug = data.contentfulTextPage.slug

  const { data: rawApolloData } = useContentful(LEGAL_TEMPLATE_QUERY, {
    slug
  })

  const textPage = useMemo(
    () =>
      legalTextPageSchema.parse(rawApolloData?.textPageCollection?.items[0]),
    [rawApolloData]
  )

  const { breadcrumbTitle, form, displayContentOnly } = textPage

  return (
    <TrackingProvider metaTitle={'legal'}>
      <SEO
        canonicalLink={canonicalLink}
        isNofollow={isNofollow}
        isNoindex={isNoindex}
        lang={locale}
        metaDescription={metaDescription?.metaDescription ?? ''}
        metaKeywords={metaKeywords}
        metaTitle={metaTitle}
      />
      <div className="prose md:prose-md lg:prose-lg prose-h2:mb-4 prose-h2:md:mb-5 whitespace-pre-line">
        {!displayContentOnly ? (
          <PromoBannerWrapper
            experimentData={promoBannerExperiment}
            type={promoType}
          />
        ) : null}

        {!displayContentOnly ? <Header {...headerData} /> : null}
        {breadcrumbTitle || slug === 'legal' ? (
          <Breadcrumbs
            steps={
              breadcrumbTitle
                ? [
                    ...(slug.includes('/video_sharing/')
                      ? [
                          {
                            label: 'Video Sharing',
                            slug: 'legal/video_sharing'
                          }
                        ]
                      : []),
                    { label: breadcrumbTitle, slug }
                  ]
                : []
            }
            template={'Legal'}
            type="main"
          />
        ) : null}
        {locale === 'en-GB' && (
          <TrustpilotUKTopBanner className="mt-7 md:mt-8" />
        )}
        <div className="max-w-8xl mx-auto mb-16 flex flex-col p-4 md:p-8">
          <LegalRichText {...textPage} />
          {form ? <SubmitYourIdeasForm {...form} /> : form}
        </div>
        {!displayContentOnly ? <Footer {...footer} /> : null}
      </div>
    </TrackingProvider>
  )
}

export default function LegalPageTemplate(props: Props) {
  return (
    <Suspense fallback={null}>
      <Content {...props} />
    </Suspense>
  )
}

export const query = graphql`
  #graphql
  query LegalQuery($id: String) {
    contentfulTextPage(id: { eq: $id }) {
      slug
      layout {
        contentful_id
      }
    }
  }
`
