import { ContentfulRichTextSchema } from '@ecomm/contentful-schemas'
import { ContentfulRichText } from '@ecomm/shared-components'
import { ChevronDown } from '@ecomm/ss-react-components'
import classNames from 'classnames'
import React from 'react'

export type AccordionProps = {
  readonly description: ContentfulRichTextSchema
  readonly isOpen: boolean
  readonly onToggle: () => void
  readonly title: string
  readonly className: string
}

export function InstallationCollapsible({
  description,
  title,
  isOpen,
  onToggle,
  className
}: AccordionProps) {
  return (
    <div
      className={classNames(
        'bg-white flex flex-col overflow-hidden px-5 py-5 md:px-10 md:py-9',
        className
      )}
    >
      <div
        className="flex cursor-pointer items-center justify-between"
        onClick={() => onToggle()}
        role="button"
      >
        <h3 className="text-lg font-medium md:text-2xl mb-0">{title}</h3>
        <span className="min-w-[24px]">
          <ChevronDown
            className={classNames(
              'h-6 w-6 transition-transform duration-200 ease-in-out',
              {
                'rotate-180': isOpen
              }
            )}
          />
        </span>
      </div>
      <div className={classNames('mr-1 md:mr-24', { hidden: !isOpen })}>
        <div className="prose-p:mb-0 pt-3 md:pt-6">
          <ContentfulRichText {...description} />
        </div>
      </div>
    </div>
  )
}
