import { LEGAL_TEMPLATE_QUERY } from '@ecomm/legal-data'
import { useContentful } from '@ecomm/shared-apollo'
import { Suspense } from 'react'

/**
 * Apollo queries to pre-load important data.
 *
 * THIS IS ONLY FOR GATSBY WHILE WE MIGRATE.
 *
 * This should include queries that are important across the site.
 * This is probably everything initially, and we can change it to be more selective as we make progress.
 */
function Queries() {
  // this is the main `/legal` page that can be reached from the footer
  // children pages are fetched on hovering over links
  useContentful(LEGAL_TEMPLATE_QUERY, {
    slug: 'legal'
  })

  return null
}

export function Prefetch() {
  return (
    <Suspense>
      <Queries />
    </Suspense>
  )
}
